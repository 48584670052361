import {mapGetters, mapMutations, mapActions} from 'vuex';
const _ = require('lodash');

export default {
    data(){
      return{
          category:null,
          product:null,
          choiceProduct: null,
          count:0,
          price:0,
          productList:[],
          categoryList:null,
          orderPayload: null,
          less_quantity: 0,
          choice_price: null,
          choice_prices: [],
          prevCount: null,
      }
    },
    computed:{
      ...mapGetters({
          products:'catalog/products',
          order:'orders/order',
          isUpsell:'orders/isUpsell',
          orderDepartmentId:'orders/orderDepartmentId',
          wholesale_order_status:'orders/wholesale_order_status',
      })
    },
    watch:{
        products(e) {
            this.getOrderProductPrice();
            if(e && e.data){
                this.productList = _.clone(e.data)
            }
        }
    },
    created() {
        this.orderPayload = _.clone(this.order);
        this.changeProducts(null);
    },
    methods:{
        getOrderProductPrice(){
            if(this.wholesale_order_status){
                if(this.validateProductLengthAndPrice()){
                    this.choice_prices = this.product.prices.filter(priceItem => priceItem.department_id === this.orderDepartmentId)
                    let minProductItem = null;
                    this.choice_prices.map(item=>{
                        if(minProductItem){
                            minProductItem = minProductItem.price >= item.price ? item : minProductItem;
                        }else{
                            minProductItem = item;
                        }
                    })
                    this.choice_price = minProductItem; 
                    this.count = minProductItem.buy_min;
                    this.price = minProductItem.price;
                }
            }else{
                if(this.validateProductLengthAndPrice()){ 
                    this.choice_prices = this.product.prices.filter(priceItem => priceItem.department_id === this.orderDepartmentId)
                    let minPrice = null;
                    this.choice_prices.map(item=>{
                        if(minPrice){
                            minPrice = minPrice > item.buy_min ? item.buy_min : minPrice;
                        }else{
                            minPrice = item.buy_min;
                        }
                    })
                    this.choice_price = this.choice_prices[0]
                    this.count = this.choice_price.buy_min;
                    this.price = this.choice_price.price;
                }else{ 
                    this.price = 0;
                }
            }
        },
        validateProductLengthAndPrice(){
          return  this.product && this.product.prices && this.product.prices.length
        },
        changePrice(){
            if(!this.wholesale_order_status){
                if(this.choice_prices){
                    this.price = this.choice_price.price
                }else{
                    this.price = 0
                }
            }
        },
        changeProductCount(){
            if(!this.wholesale_order_status){ 
                const checkMinCount = this.getActualPrice()
                checkMinCount ? this.changePrice() : this.count = this.choice_price.buy_min
            }
        }, 
        getActualPrice(){
            let wholeMinCountReady = false
            this.choice_prices.map(item=>{
                if(this.count >= item.buy_min){
                    this.choice_price = item;
                    wholeMinCountReady = true
                }
            })
            return wholeMinCountReady;
        },
        validateProductCount(){ 
            if(this.choice_price.buy_min >= this.count){
                this.count = this.choice_price.buy_min;
            }else if(this.count >= this.product.quantity){
                this.count = this.product.quantity - (this.product.quantity % this.choice_price.buy_step);
            }else {
                this.count = this.count - (this.count % this.choice_price.buy_step);
            }
        },
        addProductToOrder(){
            const addedProduct = {
                "id": this.product.id,
                "product_id": this.product.id,
                "price": this.price,
                "quantity": this.count,
                "is_upsell": this.isUpsell,
                product: {
                    "isbn": this.product.isbn,
                    "title": this.product.title,
                    "images": this.product.images,
                }
            };
            this.$emit('addProduct', addedProduct);
            this.toggleShowAddProductPopUp(false)
        },
        getSearchProduct: _.debounce(function (search){
            this.getProducts({title:search})
        },500),
        ...mapMutations({
            changeProducts: 'catalog/changeProducts',
            toggleShowAddProductPopUp: 'config/toggleShowAddProductPopUp',
        }),
        ...mapActions({
            getProducts: 'catalog/getProducts',
            editOrder:'orders/editOrder',
            showOrder:'orders/showOrder',
        })
    }
}
